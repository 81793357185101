import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';

import PageContainer from './page-container';

const NewsletterSignup = () => {
  const [complete, setComplete] = useState(false);

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={yup.object().shape({
        email: yup.string().email('Invalid email').required('Email is required'),
      })}
      onSubmit={async (values, { setFieldError }) => {
        try {
          await axios.post('/api/newsletter', values);
          setComplete(true);
        } catch (err) {
          setFieldError('email', err.response.data);
        }
      }}
    >
      {({ isSubmitting }) => (
        <PageContainer>
          <section className="mb-20 max-w-xl mx-auto">
            <Form>
              <h2 className="font-display font-black uppercase text-4xl md:text-5xl text-white text-shadow tracking-tight">
                Mailing List
              </h2>
              {complete ? (
                <p className="text-white text-shadow">
                  Thanks! You have been added to the mailing list.
                </p>
              ) : (
                <>
                  <p className="text-gray-base text-shadow">
                    Be the first to know the latest news on Black Hill Records and all of our
                    artists.
                  </p>
                  <div className="mt-4">
                    <Field
                      type="email"
                      name="email"
                      className="w-full h-14 text-white text-xl px-4 text-shadow"
                      style={{ background: 'rgba(255, 255, 255, 0.18)' }}
                    />
                    <ErrorMessage
                      name="email"
                      render={(message) => (
                        <p className="text-shadow text-white mt-4 sm:float-left text-center sm:text-left">
                          {message}
                        </p>
                      )}
                    />
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button
                      type="submit"
                      className={cx(
                        'uppercase font-black font-display text-2xl md:text-4xl h-16 border-2 border-white px-6 tracking-tight hover:opacity-75 transition-opacity duration-200 w-full sm:w-auto',
                        {
                          'opacity-25 pointer-events-none': isSubmitting,
                        }
                      )}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </Form>
          </section>
        </PageContainer>
      )}
    </Formik>
  );
};

export default NewsletterSignup;
