import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const PageContainer = ({ as: Component, children, className }) => (
  <Component className={cx('container mx-auto px-8', className)}>{children}</Component>
);

PageContainer.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageContainer.defaultProps = {
  as: 'div',
  className: '',
};

export default PageContainer;
