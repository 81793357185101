import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import NewsletterSignup from './newsletter-signup';
import Footer from './footer';

const PageLayout = ({ children, showHeaderLogo }) => (
  <div className="page pb-24 lg:pb-32">
    <Header logo={showHeaderLogo} />
    {children}
    <NewsletterSignup />
    <Footer />
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeaderLogo: PropTypes.bool,
};

PageLayout.defaultProps = {
  showHeaderLogo: true,
};

export default PageLayout;
