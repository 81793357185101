import React from 'react';
import PropTypes from 'prop-types';
import BlackHillRecordsLogo from '@globobeet/roundhill-toolkit/svg/BlackHillRecords.svg';
import cx from 'classnames';

import PageContainer from './page-container';
import Link from './link';
import MainNavLink from './main-nav-link';

const Header = ({ logo }) => (
  <PageContainer>
    <header
      className={cx('pt-8 flex flex-col md:flex-row items-center overflow-hidden', {
        'md:justify-end': !logo,
        'md:justify-between': logo,
      })}
    >
      {logo && (
        <Link href="/" className="inline-block mb-4">
          <BlackHillRecordsLogo className="text-white w-64 hover:opacity-75 transition-opacity duration-200" />
        </Link>
      )}
      <nav className="mt-8 md:mt-0 flex flex-row items-center leading-none text-base md:text-xl lg:text-3xl font-display uppercase font-black text-shadow leading-relaxed tracking-tight">
        <MainNavLink href="/about" label="About" />
        <span className="block h-6 w-1 sm:bg-white mx-2 sm:mx-4" />
        <MainNavLink href="/artists" label="Artists" />
        <span className="block h-6 w-1 sm:bg-white mx-2 sm:mx-4" />
        <MainNavLink href="/respect" label="Respect" />
        <span className="block h-6 w-1 sm:bg-white mx-2 sm:mx-4" />
        <MainNavLink href="https://shop.blackhillrecords.com" label="Shop" />
      </nav>
    </header>
  </PageContainer>
);

Header.propTypes = {
  logo: PropTypes.bool,
};

Header.defaultProps = {
  logo: true,
};

export default Header;
