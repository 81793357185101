import React from 'react';
import PropTypes from 'prop-types';

import Link from './link';

const MainNavLink = ({ label, href }) => (
  <Link href={href} className="text-white hover:opacity-75 transition-opacity duration-200">
    {label}
  </Link>
);

MainNavLink.propTypes = {
  label: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

export default MainNavLink;
