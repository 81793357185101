import React from 'react';
import RoundHillMusicLogo from '@globobeet/roundhill-toolkit/svg/RoundHillMusic.svg';
import RoundHillRecordsLogo from '@globobeet/roundhill-toolkit/svg/RoundHillRecords.svg';
import RoundHillCarlinLogo from '@globobeet/roundhill-toolkit/svg/RoundHillCarlin.svg';
import SoundHillLogo from '@globobeet/roundhill-toolkit/svg/SoundHill.svg';

import PageContainer from './page-container';
import SocialLink from './social-link';
import Link from './link';

const Footer = () => (
  <PageContainer as="footer">
    <section className="flex justify-center items-center blend-difference -ml-4">
      <div className="pl-4">
        <SocialLink service="instagram" href="https://BlackHill.lnk.to/Instagram" />
      </div>
      <div className="pl-4">
        <SocialLink service="youtube" href="https://BlackHill.lnk.to/Youtube" />
      </div>
      <div className="pl-4">
        <SocialLink service="facebook" href="https://BlackHill.lnk.to/Facebook" />
      </div>
      <div className="pl-4">
        <SocialLink service="twitter" href="https://BlackHill.lnk.to/Twitter" />
      </div>
      <div className="pl-4">
        <SocialLink
          service="spotify"
          href="https://open.spotify.com/user/nc8apzafkwn30st40qna0c3j2?si=vNGsnXRWT76q83vkCelovw"
        />
      </div>
    </section>
    <section
      className="md:flex flex-wrap justify-center items-center mt-16 text-gray-light tracking-widest text-shadow text-center"
      style={{ lineHeight: 2.25 }}
    >
      <p>
        <Link
          href="/"
          className="text-gray-light hover:text-gray-base transition-color duration-200"
        >
          Black Hill Records
        </Link>
      </p>
      <span className="hidden md:block h-6 w-px bg-white mx-4" />
      <Link
        href="https://www.google.com/maps/place/650+Fifth+Avenue/@40.7597701,-73.9790093,17z/data=!3m1!4b1!4m5!3m4!1s0x89c258fbfcb3123b:0x4d706d113db93dc4!8m2!3d40.7597701!4d-73.9768206"
        className="text-gray-light hover:text-gray-base transition-color duration-200 flex items-center"
      >
        <p>650 5th Avenue – Suite 1420 &bull; New York, NY 10019</p>
      </Link>
      <span className="hidden md:block h-6 w-px bg-white mx-4" />
      <p>
        <Link
          href="mailto:info@blackhillrecords.com"
          className="text-gray-light hover:text-gray-base transition-color duration-200"
        >
          info@blackhillrecords.com
        </Link>
      </p>
      <span className="hidden md:block h-6 w-px bg-white mx-4" />
      <p>
        <Link
          href="/"
          className="text-gray-light hover:text-gray-base transition-color duration-200"
        >
          blackhillrecords.com
        </Link>
      </p>
    </section>

    <section className="grid grid-cols-4 gap-8 mt-16 max-w-4xl mx-auto">
      <Link
        href="https://roundhillmusic.com"
        className="text-gray-600 hover:text-white transition-colors duration-200"
      >
        <RoundHillMusicLogo className="block h-12 max-w-full mx-auto" />
      </Link>
      <Link
        href="http://www.carlinmusic.com/"
        className="text-gray-600 hover:text-white transition-colors duration-200"
      >
        <RoundHillCarlinLogo className="block h-12 max-w-full mx-auto" />
      </Link>
      <Link
        href="https://roundhillmusic.com/roster?category=recording"
        className="text-gray-600 hover:text-white transition-colors duration-200"
      >
        <RoundHillRecordsLogo className="block h-12 max-w-full mx-auto" />
      </Link>
      <Link
        href="https://soundhill.com"
        className="text-gray-600 hover:text-white transition-colors duration-200"
      >
        <SoundHillLogo className="block h-12 max-w-full mx-auto" />
      </Link>
    </section>
  </PageContainer>
);

export default Footer;
