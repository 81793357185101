import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Link from './link';

const icons = {
  facebook: faFacebookF,
  instagram: faInstagram,
  twitter: faTwitter,
  youtube: faYoutube,
  spotify: faSpotify,
  web: faGlobe,
  tour: faCalendarAlt,
};

const SocialLink = ({ href, service, size }) => (
  <Link
    href={href}
    className={cx(
      'bg-white text-black font-bold text-xl rounded-full flex justify-center items-center hover:opacity-75 transition-opacity duration-200',
      {
        'w-14 h-14': size === 'lg',
        'w-12 h-12': size === 'sm',
      }
    )}
  >
    <FontAwesomeIcon icon={icons[service]} size={size === 'lg' ? '2x' : 'lg'} />
  </Link>
);

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  service: PropTypes.oneOf([
    'facebook',
    'instagram',
    'youtube',
    'twitter',
    'tour',
    'web',
    'spotify',
  ]).isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
};

SocialLink.defaultProps = {
  size: 'lg',
};

export default SocialLink;
